@use '../../../../styles/colors.scss';

#hero-header {
  align-items: center;
  height: 40px;
  display: flex;
  margin: 20px 0;
  padding: 24px;
  transition: all 200ms;

  h1 {
    color: colors.$headerBlue;
    font-size: 2.5rem;
    margin: 0;
  }

  .avatar {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }

  .avatar-main {
    background-image: url('../../../../assets/logo-no-words.png');
    height: 75px;
    margin-right: 16px;
    min-height: 75px;
    min-width: 75px;
    width: 75px;
  }

  .avatar-mobile {
    background-image: url('../../../../assets/logo-with-words.png');
    display: none;
    height: 110px;
    margin-left: 4px;
    width: 110px;
  }

  @media screen and (max-width: 599px) {
    justify-content: center;
    margin: 40px 0;
  }

  @media screen and (max-width: 424px) {
    flex-direction: column;
    margin-top: 56px;
    padding-top: 12px;
  }
}
