@use '../../../../styles/colors.scss';

#hero-background-overlay {
  background-color: colors.$backgroundOverlay;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
