#hero {
  bottom: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;

  #animated-background-gradient {
    opacity: 0.97;
    z-index: 9;
  }

  #animated-petals {
    opacity: 0.7;
    z-index: 99;
  }

  #hero-background-image {
    z-index: -1;
  }

  #hero-background-overlay {
    opacity: 0.7;
    z-index: 999;
  }

  #hero-header {
    position: relative;
    z-index: 9999;
  }

  .wave-container {
    opacity: 0.5;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;

    .wave {
      aspect-ratio: 1200/300;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-width: 800px;

      @media screen and (max-width: 800px) {
        aspect-ration: 900/300;
      }
    }

    .wave-spacer {
      background-color: white;
      height: 60px;

      @media screen and (max-width: 599px) {
        height: 120px;
      }
    }
  }
}
