@use '../../../../styles/colors.scss';

#animated-background-gradient {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
	background: linear-gradient(to left, colors.$primary, colors.$gradientTarget);
  background-size: 200% 200%;
	width: 100%;
	height: 100vh;
  animation: GradientAnimation 25s infinite;

  @keyframes GradientAnimation { 
      0%{background-position:10% 0%}
      50%{background-position:91% 100%}
      100%{background-position:10% 0%}
  }
}