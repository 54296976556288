@use '../../../styles/colors.scss';

#home-content {
  animation: fadeIn 1s;
  border-radius: 4px;
  bottom: 24px;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: auto;
  padding: clamp(12px, 4vw, 36px);
  position: absolute;
  right: 0;
  top: clamp(200px, 21vw, 380px);

  h2 {
    color: colors.$header;
  }

  p {
    color: colors.$text;
    line-height: 1.5;
  }

  .spacer {
    height: 300vh;
  }

  .content-container {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: 100%;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 599px) {
    top: 250px;
  }
}
