#animated-petals {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;

  li {
    position: absolute;
    display: block;
    list-style: none;
    background-image: url("../../../../assets/petals-75.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .petal-set {
    position: absolute;
    bottom: -20px;
    right: 0;
    top: -100px;
    left: 0;
    overflow: hidden;
  }

  .petal-set-one {
    li {
      animation: animate-one 20s linear infinite;
      bottom: -150px;

      &:nth-child(1) {
        left: 12%;
        width: 30px;
        height: 30px;
        animation-delay: 1.5s;
        animation-timing-function: ease;
      }
      &:nth-child(2) {
        left: 24%;
        width: 110px;
        height: 110px;
        animation-delay: 3.5s;
        animation-timing-function: ease-out;
      }
      &:nth-child(3) {
        left: 42%;
        width: 120px;
        height: 120px;
        animation-delay: 0s;
        animation-duration: 15s;
      }
      &:nth-child(4) {
        left: 65%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
      }
      &:nth-child(5) {
        left: 70%;
        width: 100px;
        height: 100px;
        animation-delay: 5.5s;
      }
      &:nth-child(6) {
        left: 80%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
      }
    }
  }

  .petal-set-two {
    li {
      animation: animate-one 20s linear infinite;
      bottom: -200px;

      &:nth-child(1) {
        left: 12%;
        width: 110px;
        height: 110px;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        left: 22%;
        width: 30px;
        height: 30px;
        animation-delay: 1.5s;
        animation-duration: 10s;
      }
      &:nth-child(3) {
        left: 52%;
        width: 100px;
        height: 100px;
        animation-delay: 5.5s;
        animation-duration: 12s;
      }
      &:nth-child(4) {
        left: 59%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
        animation-duration: 15s;
      }
      &:nth-child(5) {
        left: 70%;
        width: 40px;
        height: 40px;
        animation-delay: 0s;
        animation-timing-function: ease-in;
      }
      &:nth-child(6) {
        left: 86%;
        width: 60px;
        height: 60px;
        animation-delay: 3.5s;
      }
    }
  }

  .petal-set-three {
    li {
      animation: animate-two 12s linear infinite;
      bottom: -250px;

      &:nth-child(1) {
        left: 3%;
        width: 90px;
        height: 90px;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        left: 32%;
        width: 30px;
        height: 30px;
        animation-delay: 1.5s;
        animation-duration: 12s;
      }
      &:nth-child(3) {
        left: 34%;
        width: 70px;
        height: 70px;
        animation-delay: 5.5s;
      }
      &:nth-child(4) {
        left: 59%;
        width: 40px;
        height: 40px;
        animation-delay: 0s;
        animation-duration: 19s;
      }
      &:nth-child(5) {
        left: 80%;
        width: 30px;
        height: 30px;
        animation-delay: 0s;
      }
      &:nth-child(6) {
        left: 93%;
        width: 20px;
        height: 20px;
        animation-delay: 3.5s;
      }
    }
  }

  .petal-set-four {
    li {
      animation: animate-three 20s linear 1;
      bottom: 40vh;
      opacity: 0;

      &:nth-child(1) {
        left: 12%;
        width: 30px;
        height: 30px;
        animation-timing-function: ease;
      }
      &:nth-child(2) {
        left: 24%;
        width: 110px;
        height: 110px;
        animation-timing-function: ease-out;
      }
      &:nth-child(3) {
        left: 42%;
        width: 120px;
        height: 120px;
        animation-duration: 15s;
      }
      &:nth-child(4) {
        left: 53%;
        width: 60px;
        height: 60px;
      }
      &:nth-child(5) {
        left: 70%;
        width: 100px;
        height: 100px;
      }
      &:nth-child(6) {
        left: 80%;
        width: 80px;
        height: 80px;
        animation-delay: .75s;
        animation-duration: 25s;
      }
    }
  }

  .petal-set-five {
    li {
      animation: animate-three 20s linear 1;
      bottom: 20vh;
      opacity: 0;

      &:nth-child(1) {
        left: 8%;
        width: 110px;
        height: 110px;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        left: 31%;
        width: 30px;
        height: 30px;
        animation-delay: 1.5s;
      }
      &:nth-child(3) {
        left: 47%;
        width: 100px;
        height: 100px;
        animation-delay: .5s;
      }
      &:nth-child(4) {
        left: 65%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
        animation-duration: 15s;
      }
      &:nth-child(5) {
        left: 80%;
        width: 40px;
        height: 40px;
        animation-delay: 0s;
        animation-timing-function: ease-in;
      }
      &:nth-child(6) {
        left: 86%;
        width: 60px;
        height: 60px;
        animation-delay: 1s;
      }
    }
  }

  @keyframes animate-one {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: .9;
    }
    30% {
      opacity: .8;
    }
    70% {
      opacity: .5;
    }
    100% {
      transform: translateY(-115vh) rotate(360deg);
      opacity: .1;
    }
  }

  @keyframes animate-two {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: .8;
    }
    20% {
      opacity: .7;
    }
    40% {
      opacity: .6;
    }
    60% {
      opacity: .8;
    }
    80% {
      opacity: .6;
    }
    100% {
      transform: translateY(-115vh) rotate(360deg);
      opacity: .1;
    }
  }

  @keyframes animate-three {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 0;
    }
    30% {
      opacity: .5;
    }
    70% {
      opacity: .7;
    }
    100% {
      transform: translateY(-115vh) rotate(360deg);
      opacity: .1;
    }
  }
}