@use '../../../styles/colors.scss';

.standard-card {
  background-color: colors.$backgroundWhite;
  border-radius: 4px;
  padding: 8px 20px;

  .standard-card-content {
    padding: 0 0 16px;
  }
}
