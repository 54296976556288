#hero-background-image {
  background-image: url("../../../../assets/TudorHouse_1.JPG");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  rotate: 1deg;
}